import React from "react"
import Img from "gatsby-image"
import { FaExternalLinkSquareAlt } from "react-icons/fa"

const Entry = props => {
  return (
      <section key={props.data.id}>
        <div className="image">
          <Img fixed={props.data.thumbnail.fixed} />
        </div>
        <div className="text">
          <h3>{props.data.title}</h3>
          <p>{props.data.blurb.blurb}</p>
          <p>
            <strong>Our role: </strong>
            {props.data.technologies.join(", ")}
          </p>
          <a href={props.data.link}><FaExternalLinkSquareAlt style={{ marginBottom: -2 }}/> Visit</a>
        </div>
      </section>
  )
}

export default Entry
