import React from "react"

const Header = () => (
  <header>
    <div>
      <h1>ATY Creative</h1>
      <div className="subhead">
        <span>Web Design and Development in Vancouver</span>
        <span className="since">Since 2005</span>
      </div>
    </div>
  </header>
)

export default Header
