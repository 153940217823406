import React from "react"
import { graphql } from "gatsby"
import Entry from "../components/Entry"
import Layout from "../components/Layout"
import Services from "../components/Services"
import About from "../components/About"
import { FaQuoteLeft } from "react-icons/fa"

export default ({ data }) => {
  return (
    <Layout>
      <h1>
        <FaQuoteLeft style={{ marginBottom: -5, marginRight: 1 }} /> Selected
        work
      </h1>
      {data.allContentfulEntry.edges.map(({ node }) => (
        <Entry data={node} key={node.id} />
      ))}
      <h1>
        <FaQuoteLeft style={{ marginBottom: -5, marginRight: 1 }} /> What we do
      </h1>
      <Services />
      <h1>
        <FaQuoteLeft style={{ marginBottom: -5, marginRight: 1 }} /> About
      </h1>
      <About />
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulEntry(filter: { active: { eq: true } }) {
      edges {
        node {
          id
          title
          blurb {
            blurb
          }
          thumbnail {
            fixed(width: 150) {
              ...GatsbyContentfulFixed
            }
          }
          link
          technologies
          active
        }
      }
    }
  }
`
