import React from "react"
import { FaEnvelopeSquare } from "react-icons/fa"

const Footer = () => (
  <footer>
    <div class="wrapper">
      <div className="column">
        ATY Creative
        <br />
        Vancouver, BC
      </div>
      <div className="column">
          <a href="mailto:adamtyoung@gmail.com"><FaEnvelopeSquare style={{ marginBottom: -2 }} /> Email us</a>
      </div>
    </div>
  </footer>
)

export default Footer
