import React from "react"

const Services = () => (
  <>
    <p>
      ATY Creative is a full-service web design house operating out of lovely
      Vancouver, BC. We will design a layout for your site and implement it
      using standards-compliant code. We also specialize in the{" "}
      Drupal content management platform, and we
      can custom-build a solution that is right for you.
    </p>
    <ul>
      <li>Website design</li>
      <li>
        Website coding (XHTML, CSS, PHP, Javascript, Drupal, Wordpress, React,
        Gatsby)
      </li>
      <li>
        Content Management System design and implementation (Drupal, Contentful, Gatsby )
      </li>
      <li>Identity and branding</li>
      <li>Drupal theming and customization</li>
      <li>
        Information architecture and design simplicity (influenced by the work
        of <a href="http://en.wikipedia.org/wiki/Edward_Tufte">Edward Tufte</a>{" "}
        and{" "}
        <a href="http://www.vitsoe.com/en/gb/about/dieterrams/gooddesign">
          Dieter Rams
        </a>
        ).
      </li>
    </ul>
    <h3>OUR PROCESS</h3>
    <p>
      <strong>Research</strong>
      <br />
      We talk to you about your project, your design needs, and your interests.
    </p>
    <p>
      <strong>Iterative Designs</strong>
      <br />
      We create designs and solicit your feedback. This is an{" "}
      <a href="http://en.wikipedia.org/wiki/Agile_software_development">
        "agile" development process
      </a>
      , and the information architecture and design is revised often with your
      feedback.
    </p>
    <p>
      <strong>Development</strong>
      <br />
      The site is coded into being, and a demo site is set up for your final
      inspection.
    </p>
    <p>
      <strong>Deployment and Testing</strong>
      <br />
      The final product is handed over to you and we either help you through the
      launch process, or we launch the site and hand you the keys. Champagne is
      uncorked.
    </p>
  </>
)

export default Services
